<template>
  <div class="container topCon">
    <div class="ztTop"><span class="ztTitle">行业资质</span></div>
    <div class="wrapContent">
      <div class="data-hd">
        <p>行业资质<span class="desc_info">请填写商家的所属行业、特殊资质等信息</span></p>
      </div>
      <div class="data-db">
        <el-form ref="form" :model="form" label-width="132px" :rules="formRules" size="medium">
          <el-form-item label="所属行业" prop="qualificationTypeList" >
            <el-cascader
                v-model="form.qualificationTypeList"
                :options="industryList"
                @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="结算费率" prop="rate" v-if="isShow" class="rate">
            <el-input   disabled v-model="form.rate"></el-input>
          </el-form-item>
          <el-form-item label="特殊资质" v-if="isShow && isNeed">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                :class="{
                  disabled: uploadDisabled,
                }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleimgsUrl"
                :file-list.sync="qualificationFileList"
                accept="image/jpeg,image/png"
                :limit="3"
                ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <div class="el-form-info">
              <p>请提供餐饮服务许可证/三张照片（门头照、店内环境照、收银台照），若商户照片已经包含上述三个元素，少于三张照片亦可；</p>
            </div>
          </el-form-item>
          <el-form-item label="优惠费率活动（选填）" prop="activitiesId" v-if="isShow">
            <el-select v-model="form.activitiesId" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <div class="el-form-info" v-if="form.activitiesId && form.activitiesId != ''">
              <p>活动不适用范围包括以下：</p>
              <p>1.微信支付未开放行业</p>
              <p>2.不适用于以下行业：在线图书/视频/音乐、游戏、网络直播、门户论坛、网络广告及推广、软件开发业务、运营商话费充值话费代理业务、线上教培机构、保险/保险代理公司、众筹、财经资讯/荐股等金融业务、婚介平台、就业信息平台等服务平台、民办中小幼儿园、公立院校、公立医院、水电煤暖民生缴费、交通罚款业务、信用还款业务
              </p>
            </div>
          </el-form-item>
          <el-form-item label="优惠费率活动值（选填）"  prop="activitiesRate" v-if="isShow && form.activitiesId">
            <el-input class="activities_rate" v-model="form.activitiesRate">
              <template class="append" slot="append">%</template>
            </el-input>
            <div class="el-form-info">
              <p>请输入数字，支持两个小数点，需在优惠费率活动的费率范围内</p>
            </div>
          </el-form-item>
          <el-form-item label="优惠费率活动补充材料（选填）" v-if="isShow && form.activities_id">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                :class="{
                  disabled: uploadActivitiesDisabled,
                }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleActivitiesRemove"
                :on-success="handleActivitiesimgsUrl"
                :file-list.sync="activitiesAdditionsFileList"
                accept="image/jpeg,image/png"
                :limit="5"
                ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <div class="el-form-info">
              <p>请提供餐饮服务许可证/三张照片（门头照、店内环境照、收银台照），若商户照片已经包含上述三个元素，少于三张照片亦可；</p>
            </div>
          </el-form-item>
        </el-form>
        <div class="bottomBtn">
          <el-button class="btn-defalut" @click="backToMerchantApply">返回</el-button>
          <el-button class="btn-defalut" @click="saveToDraft" v-if="isWrite == true  && isSee == 'false'">保存草稿箱</el-button>
          <el-button class="success btn-defalut" @click="toNext" v-if="isWrite == true  && isSee == 'false'">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addSettlementInfo,getIndustryInfo,getSettlementInfo} from "../../../api/wxMerchant";
import {baseImgUrl} from "../../../utils/constant";
import commissionSettings from "./commissionSettings";

export default {
  name: "settlementInfo",
  components: {},
  data() {
    var validateActivitiesRate = (rule, value, callback) => {
      let number = parseFloat(value);
      console.log(number < 0.2)
      if (number< 0.2 || number > 0.6) {
        callback(new Error('优惠费率区间0.2% - 0.6%'))
      } else {
        callback()
      }
    }
    let token = localStorage.getItem("token")
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      options:[
        {
          value:'',
          label:'不参与'
        },
        {
          value:'20191030111cff5b5e',
          label:'泛行业活动      优惠费率区间0.2%-0.6%，有效期至2023年06月30日'
        }
      ],
      isShow:false,
      isNeed:false,
      dialogImageUrl: '',
      bankOptions:[],
      qualificationFileList:[],
      qualifications_list:[],
      activitiesAdditionsFileList:[],
      subjectType:'',
      videoFileList:[],
      dialogVisible: false,
      form:{
      },
      isWrite:false,
      isSee:false,
      uploadDisabled:false,
      uploadActivitiesDisabled:false,
      merchantId:'',
      legalPersonCommitment:'',
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      upParams: {
        token: token
      },
      formRules: {
        qualificationTypeList:[
          { required: true, message: '请选择所属行业', trigger: 'change' },
        ],
        rate:[
          { required: true, message: '请填写汇率', trigger: 'blur' },
        ],
        activitiesRate:[
          { required: true, message: '请填写优惠费率活动值', trigger: 'blur' },
          { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的格式' },
          {validator:validateActivitiesRate,trigger: 'blur'}
        ]
      },
      industryList:[]
    };

  },
  computed: {},
  filters:{

  },
  watch: {},
  mounted() {

  },
  created() {
    this.merchantId = this.$route.query.merchantId
    this.subjectType = this.$route.query.subjectType
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
    this.isWrite = this.$route.query.isWrite
    this.isSee = this.$route.query.isSee
    if (this.isWrite == 'true'){
      this.isWrite = true
    }else {
      if (this.isSee == 'false'){
        this.$message({
          message: '请先填写上一模块信息',
          type: 'warning',
          duration:2000
        });
      }
    }
    this.init()
    this.getSettlementInfo()
  },
  methods: {
    getSettlementInfo(){
      getSettlementInfo({merchantId:this.merchantId}).then(response=>{
        if (response.code == 200 && response.data){
          this.form = response.data
          this.form.qualificationTypeList = this.form.qualificationTypeLists
          if (this.form.qualificationTypeList){
            this.isShow = true
          }
          if (!this.form.activitiesId){
            this.form.activitiesId = ''
          }
        }

      })
    },
    handleChange(value) {
      let index = 0;
      for (let i = 0; i < this.industryList.length; i++) {
        if(this.industryList[i].value == value[0]){
          index = i;
          break;
        }
      }
      let arr = [];
      arr = this.industryList[index].children;
      for (let i = 0; i < arr.length; i++) {
        if(arr[i].value == value[1]){
          this.form.rate = arr[i].rate+'%'
          this.form.settlementId = arr[i].settlementId
          this.isShow = true
          this.isNeed = arr[i].isNeed
          this.form.qualificationType = value[1]
        }
      }
    },
    init(){
      getIndustryInfo().then(response=>{
        this.industryList =response.data
      })
    },
    handleIdLegalPersonSuccess(res,file){
      this.form.legalPersonCommitment = res.data
      this.legalPersonCommitment = this.ossUrl + this.form.legalPersonCommitment
    },
    beforeVideoUpload(file) {
      let is5M = file.size / 1024 / 1024 < 5
      if (!is5M) {
        this.$message.error({
          title: '视频上传失败',
          message: `视频大小不可超过5M`
        })
      }
      return is5M
    },
    handleSuccessVideo(response, file, fileList) {
      if(response.code!== 200) {
        this.$message({
          type: 'error',
          message: response.message
        })
        this.videoFileList = []
      }else  {
        this.videoFileList = [response.data]
        this.form.legalPersonVideo = this.videoFileList[0]
      }
    },
    handleRemoveVideo(file, fileList) {
      this.videoFileList = []
      this.form.legalPersonVideo = ''
    },
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
        let reg = new RegExp(baseImgUrl, "g")
        let url = file.url.replace(reg, '')
        for (var i = 0; i < this.qualificationFileList.length; i++) {
          if (this.qualificationFileList[i] === url) {
            this.qualificationFileList.splice(i, 1)
          }
        }
      this.form.businessAdditionPicsList = fileList
    },
  handleActivitiesRemove(file, fileList) {
      let reg = new RegExp(baseImgUrl, "g")
      let url = file.url.replace(reg, '')
      for (var i = 0; i < this.activitiesAdditionsFileList.length; i++) {
        if (this.activitiesAdditionsFileList[i] === url) {
          this.activitiesAdditionsFileList.splice(i, 1)
        }
      }
      this.form.activitiesAdditionsList = fileList
      if (this.activitiesAdditionsFileList.length >= 3){
        this.uploadActivitiesDisabled = true
      }else {
        this.uploadActivitiesDisabled = false
      }
    },
    handleActivitiesimgsUrl(response, file, fileList) {
      if (response.code === 200) {
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        this.activitiesAdditionsFileList.push(response.data)
        this.form.activitiesAdditionsList = this.activitiesAdditionsFileList
        if (this.activitiesAdditionsFileList.length >= 3){
          this.uploadActivitiesDisabled = true
        }
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        this.activitiesAdditionsFileList = fileList.pop()
      }
    },

    handleimgsUrl(response, file, fileList) {
      if (response.code === 200) {
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        this.qualificationFileList.push(response.data)
        this.form.qualificationsList = this.qualificationFileList
        if (this.qualificationFileList.length== 3){
          this.uploadDisabled = true
        }
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        this.qualificationFileList = fileList.pop()
      }
    },
    toNext(){
      //跳转下个页面且将数据进行保存
      this.form.merchantId = this.merchantId
      this.form.isSubmit = true
      addSettlementInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$router.push({path:'/bankAccountInfo',query:{'merchantId':this.merchantId,isWrite:true,isSee:this.isSee}})
          this.$message({
            message: '操作成功',
            type: 'success',
            duration:2000
          });
        }
      })
    },
    saveToDraft(){
      this.form.merchantId = this.merchantId
      addSettlementInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
    backToMerchantApply(){
      this.$message.closeAll()
      let than = this
      setTimeout(function () {
        than.$router.push({path:'/merchantApplyInfo',query:{merchantId:than.merchantId,isSee:than.isSee}})
      },0)
    }
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.topCon {
  position: relative;

}
.ztTop{
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapContent{
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.data-hd{
  margin-bottom: 30px;
  height: 14px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
}
.desc_info{
  font-size: 12px;
  color: #999;
  margin-left: 15px;
}
.descWord{
  color: #999;
  margin-top: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 193px;
  height: 109px;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon[data-v-e66b3640] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.el-input {
  position: relative;
  display: inline-block;
  width: 27%;
}
/*/deep/ .el-input__inner{*/
/*  height: 50px;*/
/*}*/
/*/deep/.el-input__inner {*/
/*  !* height: 34px !important; *!*/
/*  height: 47px!important;*/
/*  line-height: 47px !important;*/
/*  font-size: 14px !important;*/
/*}*/
.el-form-item{
  margin-bottom: 25px;
}

.btn-defalut{
  color: #333;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 30px;
}
.btn-defalut:hover{
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}

.success{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.success:hover {
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.avatar {
width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.el-form-info{
  color: #999;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 8px;
}
.videoWrap .el-icon-plus{
  font-size: 28px;
  color: #8c939d;
  line-height: 148px;
}
.videoWrap .el-upload--text {
  width: 148px;
  height: 148px;
}
.el-select>.el-input {
  display: block;
  width: 500px;
}
/deep/.rate .el-input__inner{
  color: #000;
  border: none;
  background-color: #fff;
}

/deep/.el-input[data-v-36525899] {
  position: relative;
  display: flex;
  width: 27%;
}
.el-select {
  display: inline-block;
  position: relative;
  width: 37%;
}
/deep/.el-input-group__append {
  border-left: 0;
  line-height: 32px;
}
</style>
